import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-589a8eb7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-background"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "desc"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('aiHeuristicCard.aiDesign.title')), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('aiHeuristicCard.aiDesign.titleDesc')), 1)])]);
}