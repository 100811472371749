import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7c917082"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ai-design-page"
};
import TopContent from './Component/TopContent.vue';
import CenterContent from './Component/CenterContent.vue';
// import Header from '@/components/Header'
// import PageFooter from '@/components/PageFooter'

export default {
  __name: 'Index',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(TopContent), _createVNode(CenterContent)]);
    };
  }
};